<template>
  <div class="full_popup">
    <div class="row h-100 m-0 align-items-center justify-content-center">
      <div class="col-11 col-md-8 col-lg-7 col-xl-6 col-xxl-5 card_item p-0">
        <div class="row mx-0 h-100 justify-content-center">
          <div class="col-12 pt-3 px-3 white_bg">
            <div class="row m-0 justify-content-center holder">
              <div class=" bg_holder">
                <div class="background" >
                </div>
              </div>
              <div class="col-12 above">
                <div class="row m-0 justify-content-center">
                  <div class="col-12 p-0">
                    <div class="row m-0 justify-content-end">
                      <div class="col-auto text-end pt-1 pe-1 cursor" @click="$emit('close')">
                        <ios-close-icon class="inline-icon big" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 above">
                <div class="row mx-0 justify-content-center">
                  <div class="col-12 p-0">
                    <div class="row m-0 justify-content-center">
                      <div class="col-auto text-center px-0 pb-3 border_bottom mb-3">
                        <div class="card_title">
                          NEW DEBIT ORDER
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 above white_bg">
            <div class="row mx-0 justify-content-center">
              <div class="col-12 col-md-8 mb-2">
                <div class="row mx-0 my-3 justify-content-center" v-if="!user">
                  <div class="col-12 text-center medium bigger_font"  >
                    <span>
                      Please login to complete donation
                    </span>
                  </div>
                </div>
                <div class="row mx-0 mt-4 justify-content-center" v-else>
                  <div class="col-12 p-0" v-if="page === 0 && cart.length > 0">
                    <div class="row mx-0 justify-content-center">
                      <div class="col-12 mb-3 text-center medium">
                        You have already added donations to your cart, would you like to setup a recurring payment with these selected appeals, or start a new quick recurring donation?
                      </div>
                    </div>
                  </div>
                  <div class="col-12 p-0" v-else>
                    <div class="row mx-0 justify-content-center" v-if="!isUseCart">
                      <div class="col-12 text-center medium">
                        I would like to donate the following as a recurring payment:
                      </div>
                      <div class="col-12 mb-3"  >
                        <div class="row mx-0 my-3 justify-content-center medium bigger_font">
                          <div class="col-11 col-md-10 p-0">
                            <div class="row m-0">
                              <div class="col-12 p-0">
                                <CurrencyFieldCenter placeholder="0.00" size="big" color="transparent" v-model="donation.amount" :currencySymbol="defaultCurrency" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row m-0 justify-content-center">
                          <div class="col-12 col-md-10 col-lg-8 p-0">
                            <div class="row m-0">
                              <div class="col-12 p-0 mb-3">
                                <SelectAll
                                  v-model="selectedParentProject"
                                  id="project"
                                  :options="parentProjects"
                                  placeholder="Projects"
                                  displayValue="description"
                                  :isSearch="true"
                                  color="gold"
                                />
                              </div>
                              <div class="col-12 p-0 mb-3" v-if="Object.keys(selectedParentProject).length !== 0 && childProjects.length > 0">
                                <SelectAll
                                  v-model="selectedChildProject"
                                  id="project"
                                  :options="childProjects"
                                  placeholder="Children Projects"
                                  displayValue="description"
                                  color="gold"
                                  :autoSelect="true"
                                  :isSearch="true"
                                />
                              </div>
                              <div class="col-12 p-0 mb-3">
                                <SelectAll
                                  v-if="Object.keys(selectedProject).length !== 0 && Object.keys(selectedProject.projectDonationTypeMaps).length > 0"
                                  v-model="donationType"
                                  id="project"
                                  :options="selectedProject.projectDonationTypeMaps"
                                  placeholder="Donation Type"
                                  displayValue="donationType"
                                  subDisplayValue="description"
                                  color="gold"
                                  :autoSelect="true"
                                />
                                <Select
                                  v-else
                                  v-model="donationType"
                                  id="project"
                                  :options="selectedProject.projectDonationTypeMaps"
                                  placeholder="Donation Type"
                                  displayValue="donationType"
                                  subDisplayValue="description"
                                  color="gold"
                                  :disabled="true"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 mb-3 text-center medium bigger_font">
                        <span>Donation Amount: <b>{{total}}</b></span>
                      </div>
                      <div class="col-12 mb-3">
                        <SelectAll
                          label="Debit Order Method:"
                          v-model="debitMethod"
                          id="debitMethod"
                          :options="debitMethodOptions"
                          displayValue="name"
                          color="gold"
                          :autoSelect="true"
                        />
                      </div>
                      <div class="col-6 col-md-5 mb-3">
                        <SelectAll
                          label="Debit Order Day:"
                          v-model="debitDay"
                          id="debitDay"
                          :options="debitDayOptions"
                          placeholder="Debit Order Day"
                          displayValue="name"
                          color="gold"
                          :autoSelect="true"
                        />
                      </div>
                      <div class="col-6 col-md-7 mb-3">
                        <SelectAll
                          label="Debit Order Month:"
                          v-model="debitMonth"
                          id="debitDay"
                          :options="availableMonths"
                          placeholder="Debit Order Month"
                          displayValue="name"
                          color="gold"
                          :disabled="Object.keys(debitDay).length < 1"
                          :autoSelect="true"
                        />
                      </div>
                    </div>
                    <div class="row mb-3" v-if="latestDebitOrder">
                      <div class="col-12 bold green-text-dark">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="true" id="useExisting" v-model="useExisting">
                          <label class="form-check-label" for="flexCheckDefault">
                            Use my existing account from previous debit orders
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 mb-3">
                        <SelectAll
                          label="Bank:"
                          v-model="account.bank"
                          id="bank"
                          :options="bankOptions"
                          placeholder="Bank"
                          displayValue="bankName"
                          color="gold"
                          :goUp="true"
                          :autoSelect="true"
                          :hasMore="true"
                        />
                      </div>
                      <div class="col-md-6 mb-3">
                        <TextField type="text" color="gold" placeholder="Name" label="Account Holder Name:" v-model="account.name" id="name" />
                      </div>
                      <div class="col-md-6 mb-3">
                        <TextField type="text" color="gold" placeholder="Account Number" label="Account Number:" v-model="account.number" id="number" />
                      </div>
                      <div class="col-md-6 mb-3">
                        <TextField type="text" color="gold" placeholder="Branch code" label="Branch Code:" v-model="account.branch" id="branch" />
                      </div>
                    </div>
                    <div class="row my-3">
                      <div class="col-auto bold green-text-dark">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="true" id="acceptTerms" v-model="acceptTerms">
                          <label class="form-check-label" for="flexCheckDefault">
                            I accept the terms and conditions.
                          </label>
                        </div>
                      </div>
                      <div class="col bold green-text-light cursor p-0">
                        <span @click="showTerms = !showTerms">Read Here</span>
                      </div>
                    </div>
                    <div class="row font12" v-if="showTerms">
                      <div class="col-12 mb-3">
                        I / We hereby request, "instruct" and authorise you to draw against my / our account with the above-mentioned bank stipulated amount. I / We understand that all such withdrawals from my / our bank account by you shall be treated as though they had been signed by me / us personally.
                      </div>
                      <div class="col-12 mb-3">
                        We understand that the withdrawals hereby authorise will be processed by computer through a system known as the ACB Magnet Tape Service and I / We also understand that details of each withdrawal will be printed on my bank statement or on accompanying voucher.
                      </div>
                      <div class="col-12 mb-3">
                        This authority may be cancelled by me / us by giving you thirty days' notice in writing. I / We shall not be entitled to any amounts, which you have withdrawn in terms of the above transaction while authority was in force.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 p-0 align-self-end above">
            <div class="row m-0 background-green py-3 justify-content-center">
              <!-- !PAGE -->
              <div class="col-10" v-if="user && page === 0 && cart.length > 0">
                <div class="row">
                  <div class="col-12 mb-2">
                    <Button color="green_light" width="100" btnText="Continue with Donations in my Cart" icon="arrow" @buttonClicked="useCart" class="d-none d-md-block">
                      <IconArrowForward color="white" />
                    </Button>
                    <Button color="green_light" width="100" btnText="Continue with my Cart" icon="arrow" @buttonClicked="useCart" class="d-md-none">
                      <IconArrowForward color="white" />
                    </Button>
                  </div>
                  <div class="col-12 mb-2">
                    <Button color="green_light" width="100" btnText="Start New Quick Recurring Donation" icon="arrow" @buttonClicked="startNew" class="d-none d-md-block">
                      <IconArrowForward color="white" />
                    </Button>
                    <Button color="green_light" width="100" btnText="Start New Donation" icon="arrow" @buttonClicked="startNew" class="d-md-none">
                      <IconArrowForward color="white" />
                    </Button>
                  </div>
                </div>
              </div>
              <!-- END !PAGE -->
              <div class="col-12 px-0" v-else>
                <div class="row mx-0 justify-content-center">
                  <div class="col-auto" v-if="cart.length > 0">
                    <Button color="green_light" width="100" btnText="Back" icon2="arrow" @buttonClicked="page = 0">
                      <template v-slot:iconStart>
                        <IconArrowBack color="white" />
                      </template>
                    </Button>
                  </div>
                  <div class="col-auto" v-if="!user">
                    <Button color="gold" btnText="Login to Complete" icon="arrow" @buttonClicked="isLogin = true">
                      <IconArrowForward color="black" size="size20" />
                    </Button>
                  </div>
                  <div class="col-auto" v-else>
                    <Button color="green_light" width="100" btnText="Setup Debit Order" icon="arrow" @buttonClicked="processDonation" :disabled="!acceptTerms" v-if="cart.length > 0" :isLoading="isLoading">
                      <IconArrowForward color="white" />
                    </Button>
                    <Button color="green_light" width="100" btnText="Setup Debit Order" icon="arrow" @buttonClicked="processDonation" :disabled="donation.amount < 0.01 || Object.keys(selectedProject).length === 0 || !donationType || !acceptTerms" v-else-if="cart.length < 1" :isLoading="isLoading">
                      <IconArrowForward color="white" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DebitOrderCompletePopup v-if="isComplete" @close="closeAll" />
    <transition name="fade">
      <LoginPopup v-if="isLogin" @close="isLogin = false" @loggedIn="isLogin = false"/>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'
import { DateTime } from 'luxon'

export default {
  components: {
    Button: defineAsyncComponent(() => import('../components/Button.vue')),
    SelectAll: defineAsyncComponent(() => import('../components/SelectAll.vue')),
    Select: defineAsyncComponent(() => import('../components/SelectProject.vue')),
    TextField: defineAsyncComponent(() => import('../components/TextField.vue')),
    CurrencyFieldCenter: defineAsyncComponent(() => import('../components/CurrencyFieldCenter.vue')),
    IconArrowForward: defineAsyncComponent(() => import('../components/icons/IconArrowForward.vue')),
    IconArrowBack: defineAsyncComponent(() => import('../components/icons/IconArrowBack.vue')),
    DebitOrderCompletePopup: defineAsyncComponent(() => import('./DebitOrderCompletePopup.vue')),
    LoginPopup: defineAsyncComponent(() => import('@/views/auth/LoginPopup.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue'))
  },
  name: 'Setup Debit Order',
  data () {
    return {
      page: 1,
      paymentDetails: {
        name: '',
        number: '',
        expiry: '',
        cvv: ''
      },
      frequency: 'MONTHLY',
      startDate: null,
      endDate: '',
      untilCancelled: true,
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      donorType: '',
      selectedProject: {},
      selectedParentProject: {},
      selectedChildProject: {},
      donation: {
        amount: null,
        currency: this.defaultCurrency,
        projectId: '',
        donationTypes: []
      },
      donationType: '',
      debitMonth: {},
      debitMethod: {},
      debitMethodOptions: [
        {
          name: 'Debit Order (Bank)'
        }
      ],
      debitMonthOptions: [
        {
          month: 1,
          name: 'January'
        },
        {
          month: 2,
          name: 'February'
        },
        {
          month: 3,
          name: 'March'
        },
        {
          month: 4,
          name: 'April'
        },
        {
          month: 5,
          name: 'May'
        },
        {
          month: 6,
          name: 'June'
        },
        {
          month: 7,
          name: 'July'
        },
        {
          month: 8,
          name: 'August'
        },
        {
          month: 9,
          name: 'September'
        },
        {
          month: 10,
          name: 'October'
        },
        {
          month: 11,
          name: 'November'
        },
        {
          month: 12,
          name: 'December'
        }
      ],
      debitDay: {},
      debitDayOptions: [
        {
          name: '1'
        },
        {
          name: '3'
        },
        {
          name: '15'
        },
        {
          name: '25'
        }
      ],
      useExisting: false,
      account: {
        bank: '',
        number: '',
        branch: '',
        name: ''
      },
      showTerms: false,
      acceptTerms: false,
      isLoading: false,
      isLogin: false,
      isComplete: false,
      isUseCart: false,
      currentDonation: []
    }
  },
  mounted () {
    if (this.user && this.cart.length < 1) {
      this.page = 1
    } else {
      this.page = 0
    }
  },
  beforeMount () {
    this.donation.currency = this.defaultCurrency
    this.fetchBankOptions()
    this.fetchBankAllOptions()
  },
  watch: {
    selectedProject () {
      if (this.selectedProject && this.selectedProject.projectID) {
        this.donation.projectId = this.selectedProject.projectID
      }
    },
    selectedParentProject () {
      if (this.selectedParentProject && Object.keys(this.selectedParentProject).length > 0) {
        if (this.selectedChildProject && Object.keys(this.selectedChildProject).length > 0) {
          this.selectedChildProject = {}
          this.selectedProject = this.selectedParentProject
        } else {
          this.selectedProject = this.selectedParentProject
        }
      }
    },
    selectedChildProject () {
      if (this.selectedChildProject && Object.keys(this.selectedChildProject).length > 0) {
        this.selectedProject = this.selectedChildProject
      }
    },
    useExisting () {
      if (this.useExisting) {
        this.account.number = this.latestDebitOrder.donorBankAccount.accountNumber
        this.account.name = this.latestDebitOrder.donorBankAccount.accountHolderName
        this.account.branch = this.latestDebitOrder.donorBankAccount.branchCode
        this.account.bank = this.bankOptions.find(bank => bank.bankName === this.latestDebitOrder.donorBankAccount.bankName)
      }
    },
    account: {
      deep: true,
      handler (val) {
      }
    }
  },
  computed: {
    ...mapGetters([
      'adminProjects', 'cart', 'user', 'bankOptions', 'bankAllOptions', 'debitOrders'
    ]),
    parentProjects () {
      return this.adminProjects.filter(project => {
        if (project.parentProjectID !== 0) {
          return false
        }
        return true
      })
    },
    // availableDays () {
    //   const currentDay = DateTime.now().day
    //   const currentMonth = DateTime.now().month
    //   return this.debitMonthOptions.filter(month => {
    //     if (month !== this.selectedParentProject.projectID) {
    //       return false
    //     }
    //     return true
    //   })
    // },
    availableMonths () {
      const currentDay = DateTime.now().day
      const currentMonth = DateTime.now().month
      return this.debitMonthOptions.filter(month => {
        if (month.month < currentMonth) {
          return false
        }
        if (this.debitDay.name && parseInt(this.debitDay.name) <= currentDay && month.month === currentMonth) {
          return false
        }
        return true
      })
    },
    childProjects () {
      const arr = this.adminProjects.filter(project => {
        if (this.selectedParentProject && project.parentProjectID !== this.selectedParentProject.projectID) {
          return false
        }
        return true
      })
      arr.sort((a, b) => (a.description > b.description ? 1 : -1))
      return arr
    },
    total () {
      if (this.isUseCart && this.cart.length > 0) {
        let ret = 0
        this.cart.forEach(item => {
          ret += Number(item.amount)
        })
        return this.cart[0].currency + ' ' + parseFloat(Math.round(ret * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      } else {
        return this.defaultCurrency + ' ' + parseFloat(Math.round(this.donation.amount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      }
    },
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    },
    latestDebitOrder () {
      if (this.debitOrders && this.debitOrders.length > 0) {
        return this.debitOrders[0]
      } else {
        return null
      }
    }
  },
  methods: {
    ...mapActions(['addToCart', 'clearCart', 'fetchBankOptions', 'fetchBankAllOptions', 'bankDonationRecurring', 'debitOrdersZA']),
    useCart () {
      this.isUseCart = true
      this.currentDonation = this.cart
      this.page = 2
    },
    startNew () {
      this.isUseCart = false
      this.currentDonation = []
      this.page = 1
    },
    addDonationToCart () {
      this.selectedProject.projectDonationTypeMaps.forEach((dType) => {
        const obj = { type: dType, amount: null }
        if (dType !== this.donationType) {
          this.donation.donationTypes.push(obj)
        } else {
          this.donation.donationTypes.push({ type: this.donationType, amount: this.donation.amount })
        }
      })
      this.currentDonation.push(this.donation)
      this.addToCart(this.donation)
      this.isAdded = true
      this.donation = {
        amount: null,
        currency: this.defaultCurrency,
        projectId: '',
        donationTypes: []
      }
      this.selectedProject = {}
      this.selectedParentProject = {}
      this.selectedChildProject = {}
      setTimeout(() => {
        this.isAdded = false
      }, 2000)
    },
    async processDonation () {
      this.isLoading = true
      if (!this.isUseCart) {
        await this.addDonationToCart()
      }
      const yearNow = DateTime.now().year
      const startdate = DateTime.fromObject({ year: yearNow, month: this.debitMonth.month, day: this.debitDay.name }).toMillis()
      const options = {
        donorBankAccount: {
          bankName: this.account.bank.bankName,
          accountHolderName: this.account.name,
          branchCode: this.account.branch,
          accountNumber: this.account.number
        },
        startDate: startdate,
        debitOrderDay: parseInt(this.debitDay.name),
        untilCancelled: true,
        endDate: '',
        frequency: this.frequency.toUpperCase(),
        recurring: true,
        reference: 'Website reference',
        transactionDTO: {
          donationDtoList: [],
          userID: this.user.id,
          paymentOption: 5,
          giftAid: false,
          ownMoney: true
        }
      }
      this.currentDonation.forEach((item) => {
        item.donationTypes.forEach((donationType) => {
          if (donationType.amount > 0) {
            const obj = {
              amount: donationType.amount,
              donationTypeID: donationType.type.donationType.donationTypeID,
              projectID: item.projectId,
              currencyId: 1
            }
            options.transactionDTO.donationDtoList.push(obj)
          }
        })
      })
      if (this.isUK) {
        await this.bankDonationRecurring(options)
      } else {
        await this.debitOrdersZA(options)
      }
      this.isLoading = false
      this.isComplete = true
    },
    setFrequency (val) {
      this.frequency = val
    },
    close () {
      this.$emit('close')
    },
    closeAll () {
      this.isComplete = false
      this.$router.push('/debitorders')
      this.$emit('closeAll')
    }
  }
}

</script>

<style scoped>
.full_popup {
  position: fixed;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
}

.card_item {
  /* background-color: #fff; */
  border-radius: 22px;
  box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.card_title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 4.3px;
}
.bigger_font {
  font-size: 20px;
}
.font09 {
  font-size: 0.9rem;
}
.border_bottom {
  border-bottom: 1px solid var(--gold-color);
}
.holder {
  position: relative;
  z-index: 2;
}
.above {
  z-index: inherit;
}
.bg_holder {
  position: absolute;
  left: -1.5rem;
  top: -1.5rem;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  background-image: linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  max-width: calc(100% + 3rem);
  width: calc(100% + 3rem);
  height: calc(100% + 1.5rem);
  padding: 0;
}
.background {
  background-image: url(../assets/images/bg.png);
  position: absolute;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  background-size: 30% auto;
  opacity: 0.03;
  background-position: top center;
  height: 100%;
  width: 100%;
}
.background-green {
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
}
.wizard_step {
  border-radius: 50%;
  color: var(--green-color-dark);
  background-color: var(--gold-color);
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 0%);
  font-family: "quicksand_bold", Sans-serif;
  font-size: 11px;
  width: 30px;
  height: 30px;
}
.wizard_step.active {
  background-color: var(--green-color-dark);
  color: var(--gold-color);
}
.wizard_divider {
  border-bottom: 1px solid rgba(104, 103, 103, 0.4);
  width: 15px;
  height: 16px;
}
.small_font {
  font-size: 11px;
  font-family: "quicksand_bold", Sans-serif;
}
.opacity {
  opacity: 0;
}
</style>
